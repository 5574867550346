// Here you can add other styles
.noti-dropdown-item.dropdown-item {
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 20px;
    div.noti-icon {
        flex-basis: 10%;
    }
    div.noti-message {
        flex-basis: 80%;
        white-space: break-spaces;

        .noti-title {
            font-weight: bold;
            font-size: 20px;
        }
        .noti-body {
            font-size: 16px;
        }
    }
}
.noti-deopdown-menu.dropdown-menu {
    width: 400px;
    transform: none !important;
    inset: unset  !important;
    position: absolute;
    margin: 0px;
    top: 30px !important;
    right: 0 !important;
}
.modal {
    z-index: 999999999999999;
}

.termsAndConditions .ck.ck-editor__main>.ck-editor__editable{
    height: 300px;
}
// .bg-custom-1 {
//     background-color: #FFF4DE;
// }
// .bg-custom-2 {
//     background-color: #E4F6EA;
// }
// .bg-custom-3 {
//     background-color: #C9F7F5;
// }
// .bg-custom-4 {
//     background-color: #D7F2F1;
// }
// .bg-custom-5 {
//     background-color: #E4EDED;
// }
// .bg-custom-6 {
//     background-color: #FFE2E5;
// }
// .bg-custom-7 {
//     background-color: #F0E9F2;
// }
// .bg-custom-8 {
//     background-color: #E1F0FF;
// }
// .bg-custom-9 {
//     background-color: #F2E8E9;
// }
// .bg-custom-10 {
//     background-color: #E9EDF9;
// }
.bg-custom-1 {
    background-color: #FFCF99;
}
.bg-custom-2 {
    background-color: #960200;
}
.bg-custom-3 {
    background-color: #499167;
}
.bg-custom-4 {
    background-color: #CE6C47;
}
.bg-custom-5 {
    background-color: #FFD046;
}
.bg-custom-6 {
    background-color: #7D8CA3;
}
.bg-custom-7 {
    background-color: #9D96B8;
}
.bg-custom-8 {
    background-color: #9A7197;
}
.bg-custom-9 {
    background-color: #E5B181;
}
.bg-custom-10 {
    background-color: #F4B9B2;
}
.dashboard-stat-card .card-body > div , .dashboard-stat-card .card-body > div > div {
    font-size: 1rem;
    @media (min-width:992px) and (max-width:1199.99px){
        font-size: 0.85rem;
    }
}
.dashboard-stat-card {
    cursor: pointer;
}